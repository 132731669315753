import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Parent of a location.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">union</GQLKeyword> LocationsParent <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator"> | </GQLOperator><GQLLink href="/ecdn-api-objects/Region" mdxType="GQLLink">Region</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Types`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ecdn-api-objects/Location"
        }}>{`Location`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ecdn-api-objects/Region"
        }}>{`Region`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      